const awsConfig = {
  aws_cognito_identity_pool_id:
    "us-east-1:3293978e-06c8-454a-8a33-55481da7d6bd",
  aws_cognito_region: "us-east-1",
  aws_project_region: "us-east-1",
  aws_user_pools_id: "us-east-1_rNpKZaSDn",
  aws_user_pools_web_client_id: "6mkpc1hr4m6hg0q60k3j9kafmp",
};

export default awsConfig;
