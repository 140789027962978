import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';


const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/auth' || location.pathname === '/auth/') {
      navigate('/auth/login', { replace: true });
    }
  }, [location]);
  return (
    <div
      className={`min-h-screen h-full bg-top bg-cover md:bg-center ${styles.authContainer}`}
    >
      <Outlet />
    </div>
  );
};
export default AuthLayout;
