import React, { lazy, useEffect } from 'react';
import { createBrowserRouter, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  AuthRoutes,
  DashboardRoutes,
  OnboardingRoutes
} from '../config/routes';
import { CardElement, useElements, useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AuthLayout from '../layouts/Auth';

// Auth Routes
const Login = lazy(() => import('../views/auth/Login'));
const Register = lazy(() => import('../views/auth/Register'));

// Dashboard Routes
const DashboardLayout = lazy(() => import('../layouts/Dashboard'));
const Reports = lazy(() => import('../views/dashboard/Reports'));
const Fundings = lazy(() => import('../views/dashboard/Fundings'));
const Cards = lazy(() => import('../views/dashboard/Cards'));
const Kyb = lazy(() => import('../views/dashboard/Kyb'));

const Settings = lazy(() => import('../views/dashboard/setting'));

// OnBoarding Routes
const Kyblayout = lazy(() => import('../layouts/Onboarding'));
const KybEntities = lazy(() => import('../views/onBoarding/Kyb-E1'));
const KybEntities1 = lazy(() => import('../views/onBoarding/Kyb-E2'));
const KybEntities2 = lazy(() => import('../views/onBoarding/Kyb-E3'));
const KybEntities3 = lazy(() => import('../views/onBoarding/Kyb-E4'));

const STRIPE_PUBLISHABLE_KEY = "pk_test_51Ji8URCOSFuPAJOfq8V8Phu9PhOCsYdbh82iwHz7nPIan2JWqNGYQsO4LIvsqBVw1jKVxOlqutf1tOzNIlDhTyt300EWe1Ztxt"

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY as string, {
  stripeAccount: localStorage.getItem("account_token") as string
});

function NavigateToLoginWithQueryParams() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryString = queryParams.toString();
    const loginRoute = `${AuthRoutes.LOGIN}${queryString ? `?${queryString}` : ''}`;
    navigate(loginRoute);
  }, [navigate, location.search]);


  return null;
}


const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: AuthRoutes.LOGIN,
        element: <Login />
      },
      {
        path: AuthRoutes.REGISTER,
        element: <Register />
      }
    ]
  },
  {
    path: '/onBoarding',
    element: <Kyblayout />,
    children: [
      {
        path: OnboardingRoutes.KYBENTITIES,
        element: <KybEntities />
      },
      {
        path: OnboardingRoutes.KYBENTITIES1,
        element: <KybEntities1 />
      },
      {
        path: OnboardingRoutes.KYBENTITIES2,
        element: <KybEntities2 />
      },
      {
        path: OnboardingRoutes.KYBENTITIES3,
        element: <KybEntities3 />
      }
    ]
  },
  {
    path: '/dashboard',
    element: <DashboardLayout />,
    children: [
      {
        path: DashboardRoutes.REPORTS,
        element: <Reports />
      },

      {
        path: DashboardRoutes.FUNDINGS,
        element: <Fundings />
      },
      {
        path: DashboardRoutes.CARDS,
        element:  <Elements stripe={stripePromise}><Cards /></Elements>
      },
      {
        path: DashboardRoutes.KYB,
        element: <Kyb />
      },
      {
        path: DashboardRoutes.KYBENTITIES,
        element: <KybEntities />
      },
      {
        path: DashboardRoutes.KYBENTITIES1,
        element: <KybEntities1 />
      },
      {
        path: DashboardRoutes.KYBENTITIES2,
        element: <KybEntities2 />
      },
      {
        path: DashboardRoutes.KYBENTITIES3,
        element: <KybEntities3 />
      },
      {
        path: DashboardRoutes.SETTINGS,
        element: <Settings />
      }
    ]
  },
  {
    path: '*',
    element: <NavigateToLoginWithQueryParams />
  }
]);

export default router;
