export enum AuthRoutes {
  REGISTER = "/auth/register",
  LOGIN = "/auth/login",
  FORGOT_PASSWORD = "/auth/forgot-password",
}

export enum DashboardRoutes {
  REPORTS = "/dashboard/reports",
  CARDS = "/dashboard/cards",
  FUNDINGS = "/dashboard/fundings",
  SETTINGS = "/dashboard/settings",
  OVERVIEW = "/dashboard/overview",
  SPEND_BREAKDOWN = "/dashboard/spend-breakdown",
  KYB = "/dashboard/kyb",
  KYBENTITIES = "/dashboard/kybE1",
  KYBENTITIES1 = "/dashboard/kybE2",
  KYBENTITIES2 = "/dashboard/kybE3",
  KYBENTITIES3 = "/dashboard/kybE4",
  
}
export enum OnboardingRoutes{
  KYBENTITIES = "/onBoarding/kybE1",
  KYBENTITIES1 = "/onBoarding/kybE2",
  KYBENTITIES2 = "/onBoarding/kybE3",
  KYBENTITIES3 = "/onBoarding/kybE4",
}

