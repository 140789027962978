import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AppContextType {
  loading: boolean;
  showCardPage: boolean;
  showSidebar: boolean;
  toggleLoading: (isLoading: boolean) => void;
  toggleCardPage: (state: boolean) => void;
  toggleSidebar: (state: boolean) => void;
}

export const AppContext = createContext<AppContextType>({
  loading: false,
  showCardPage: false,
  showSidebar: false,
  toggleLoading: () => {
    console.warn("toggleLoading function called, but no implementation provided.");
  },
  toggleCardPage: () => {
    console.warn("toggleLoading function called, but no implementation provided.");
  },
  toggleSidebar: () => {
    console.warn("toggleLoading function called, but no implementation provided.");
  }
});

export const useAppContext = () => useContext(AppContext);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [showCardPage, setShowCardPage] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleLoading = (isLoading: boolean) => {
    setLoading(isLoading); 
  };

  const toggleCardPage = (state: boolean) => {
    setShowCardPage(state);
  }

  const toggleSidebar = (state: boolean) => {
    setShowSidebar(state);
  }

  const contextValue: AppContextType = {
    loading,
    toggleLoading,
    showCardPage,
    toggleCardPage,
    showSidebar,
    toggleSidebar
  };

  return (
    <AppContext.Provider value={contextValue}>
      {children}
    </AppContext.Provider>
  );
};