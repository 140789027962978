import React, { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import Amplify from 'aws-amplify';
import awsConfig from './aws-config';
Amplify.configure(awsConfig);
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Auth0Provider } from '@auth0/auth0-react';
import providerConfig from './config/auth0';
import { AppProvider } from './context/AppContext';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5
      }
    }
  });

  return (
    <Suspense>
      <QueryClientProvider client={queryClient}>
        <Auth0Provider {...providerConfig}>
          <ToastContainer />
          <AppProvider>
            <RouterProvider router={router} />
          </AppProvider>
        </Auth0Provider>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
